import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Account } from '../models/account'
import { createHttpBody } from '../utils/http-body'
import { createHttpParams } from '../utils/http-params'
import { ApiResponse } from '../models/apiResponse'
import { SocketInterceptorService } from './socket-interceptor.service'
import { AccountHasAccess } from '../models/account-has-access'

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private url = `${environment.url}/accounts/` //server
  mlAccountsList: Account[] = []

  constructor(
    private http: HttpClient,
    private socketInterceptorService: SocketInterceptorService
  ) { }

  getAccountById(accountId: string, associatedAttributesType?: number): Promise<Account> {
    const httpParams = createHttpParams({
      associatedAttributes: associatedAttributesType
    })

    return firstValueFrom(this.http.get<Account>(`${this.url}${accountId}`, { params: httpParams }))
  }

  getAccountByIdViaSockets(accountId: number, associatedAttributes?: number): Promise<Account> {
    return this.socketInterceptorService.makeGetRequest('accounts', accountId, {
      associatedAttributes
    })
  }

  searchAccountByOrgId(
    orgId: number,
    associatedAttributes?: number
  ): Promise<ApiResponse<Account>> {
    const httpParams = createHttpParams({
      organization_id: orgId,
      associatedAttributes: associatedAttributes
    })

    return firstValueFrom(
      this.http.get<ApiResponse<Account>>(`${this.url}`, { params: httpParams })
    )
  }

  getAccountsWithAccessToStore(storeId: number): Promise<AccountHasAccess[]> {
    const httpHeaders = new HttpHeaders({
      'X-Service-Method': 'getAccountsForAppointment',
      'Content-Type': 'application/json'
    })
    return firstValueFrom(
      this.http.post<AccountHasAccess[]>(`${this.url}`, { storeId }, { headers: httpHeaders })
    )
  }

  searchAccountByNameOrEmail(
    value: string,
    page?: string,
    size?: string,
    associatedAttributes?: number
  ): Promise<ApiResponse<Account>> {
    let httpParams = createHttpParams({
      name: value,
      email: value,
      page: page,
      size: size,
      associatedAttributes: associatedAttributes
    })
    return firstValueFrom(
      this.http.get<ApiResponse<Account>>(`${this.url}`, { params: httpParams })
    )
  }

  createAccount(
    name: string,
    email: string,
    contact_id: string,
    language_id: string,
    organization_id: string
  ): Promise<Account> {
    const httpBody = createHttpBody({
      name: name,
      email: email,
      contact_id: contact_id,
      language_id: language_id,
      organization_id: organization_id,
      active: 1
    })
    return firstValueFrom(this.http.post<Account>(this.url, httpBody))
  }

  updateShowTutorialParam(accountId: number, showTutorial: boolean): Promise<Account> {
    return firstValueFrom(this.http.put<Account>(`${this.url}${accountId}`, { showTutorial }))
  }

  deleteAccount(accountId: number) {
    return firstValueFrom(this.http.delete<Account>(`${this.url}${accountId}`))
  }

  updateAccount(
    accountId: string,
    name: string,
    email: string,
    signature?: string,
    signature_id?: number,
    password?: string,
    address_id?: number,
    contact_id?: number,
    picture_id?: number,
    role?: string,
    request_barcode_id?: number
  ): Promise<Account> {
    let httpBody = createHttpBody({
      name: name,
      email: email,
      signature_text: signature,
      password: password,
      address_id: address_id,
      contact_id: contact_id,
      picture_id: picture_id,
      signature_id: signature_id,
      role: role,
      request_barcode_id: request_barcode_id
    })

    return firstValueFrom(this.http.put<Account>(`${this.url}${accountId}`, httpBody))
  }

  updateAccountState(accountId: string, active: number): Promise<Account> {
    return firstValueFrom(this.http.put<Account>(`${this.url}${accountId}`, { active }))
  }

  updateAccountRequestExams(accountId: string, requestExams: number): Promise<Account> {
    return firstValueFrom(
      this.http.put<Account>(`${this.url}${accountId}`, {
        request_exams: requestExams
      })
    )
  }

  getAccounts(
    pageNumber: number,
    pageSize: number,
    associatedAttributesType?: number
  ): Promise<ApiResponse<Account>> {
    const httpParams = createHttpParams({
      page: pageNumber.toString(),
      size: pageSize,
      associatedAttributes: associatedAttributesType
    })
    return firstValueFrom(this.http.get<ApiResponse<Account>>(this.url, { params: httpParams }))
  }

  getManagerAccounts(): Promise<ApiResponse<Account>> {
    const httpParams = createHttpParams({
      password: 'gestor'
    })
    return firstValueFrom(this.http.get<ApiResponse<Account>>(this.url, { params: httpParams }))
  }
}
