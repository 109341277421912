import { ExamTypes } from './../utils/examTypes'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { firstValueFrom, lastValueFrom, Subject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { LoginResult } from '../models/login'
import { AccountService } from './account.service'
import { Account } from '../models/account'
import { NotificationService } from './notifications.service'
import { PatientLogin } from '../models/login'
import { FeathersjsService } from './feathersjs.service'
import { GenericRealTimeService } from './generic-real-time.service'
import { Store } from '../models/store'
import { Location } from '@angular/common'
import { OrganizationService } from './organization.service'
import { UserRoles } from '../utils/userRoles'
import { ApiResponse } from '../models/apiResponse'
import { Issue } from '../models/issue'
import { TicketsRealTimeService } from './tickets-real-time.service'
import { AppToastService, ToastType } from './app-toast-service.service'
import { Appointment } from '../models/appointment'
import { HeaderInfoWarning } from '../models/header-info-warning'
import { AppointmentUtils } from '../utils/appointmentUtils'
import { ExamsRealTimeService } from './exams-real-time.service'
import { ExamRequestGroup } from '../models/exam-request-group'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticated = false
  private authStatusListener = new Subject<boolean>()
  public loggedInUserStoreId!: number
  public isUserMobile!: boolean
  private canTransferExams!: boolean

  private url = `${environment.url}/account-authentication`

  private httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private feathersjsService: FeathersjsService,
    private genericRealTimeService: GenericRealTimeService,
    private examsRealTimeService: ExamsRealTimeService,
    private location: Location,
    private organizationService: OrganizationService,
    private ticketsRealTimeService: TicketsRealTimeService,
    private zone: NgZone,
    private toastService: AppToastService
  ) { }

  async getCanTransferExams(): Promise<boolean> {
    if (this.canTransferExams == undefined) {
      const loggedInAccountOrgInfo = await this.organizationService.getOrganizationById(
        this.getLoggedInUserOrgId().toString()
      )
      this.canTransferExams = loggedInAccountOrgInfo.transfer_exams
    }

    return this.canTransferExams
  }

  register(name: string, email: string, password: string, code: number): Promise<any> {
    return firstValueFrom(
      this.http.post(`${this.url}/register`, { name, email, password, code }, this.httpOptions)
    )
  }

  recoverPassword(email: string): Promise<any> {
    return lastValueFrom(
      this.http.post(`${this.url}/recover_password`, { email }, this.httpOptions)
    )
  }

  updatePassword(token: string, password: string): Promise<any> {
    return lastValueFrom(
      this.http.post(`${this.url}/update_password`, { token, password }, this.httpOptions)
    )
  }

  saveUserInfoOnLogin(loginResult: LoginResult) {
    localStorage.setItem('userInfo', JSON.stringify(loginResult.account))
    localStorage.setItem('showTour', loginResult.account.showTutorial ? '1' : '0')

    localStorage.setItem('expiresAt', loginResult.authentication.payload.exp.toString())

    this.isAuthenticated = true
    this.authStatusListener.next(true)
  }

  savePatientInfoOnLogin(patientLoginInfo: PatientLogin) {
    localStorage.setItem('personInfo', JSON.stringify(patientLoginInfo.person))
    localStorage.setItem('expiresAt', patientLoginInfo.authentication.payload.exp.toString())

    this.isAuthenticated = true
    this.authStatusListener.next(true)
  }

  isUserManager(): boolean {
    const userRoles = this.getUserRoles()

    return userRoles.includes(UserRoles.MANAGER)
  }

  isUserSupervisor(): boolean {
    const userRoles = this.getUserRoles()

    return userRoles.includes(UserRoles.SUPERVISOR)
  }

  isTechOrDoctor(): boolean {
    const userRoles = this.getUserRoles()

    return userRoles.includes(UserRoles.TECHLEVEL1) || userRoles.includes(UserRoles.TECHLEVEL2) || userRoles.includes(UserRoles.DOCTOR)
  }

  isExternalTechOrTechLevel1(): boolean {
    const userRoles = this.getUserRoles()

    return userRoles.includes(UserRoles.TECHLEVEL1) || userRoles.includes(UserRoles.EXTERNALTECH)
  }

  isStoreLevel1OrLevel2(): boolean {
    const userRoles = this.getUserRoles()

    return userRoles.includes(UserRoles.STORELEVEL1) || userRoles.includes(UserRoles.STORELEVEL2)
  }

  isUserAdmin(): boolean {
    const userRoles = this.getUserRoles()

    return userRoles.includes(UserRoles.ADMIN)
  }

  async updateUserInfo() {
    const currentUserInfo = this.getUserInfo()

    const updatedUserInfo = await this.accountService.getAccountById(currentUserInfo.id.toString())

    localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo))
  }

  getUserProfilePic(): string {
    const profilePic = localStorage.getItem('profilePic')

    return profilePic ? profilePic : ''
  }

  getUserInfo(): Account {
    let userInfo
    if (localStorage.getItem('userInfo')) {
      userInfo = JSON.parse(localStorage.getItem('userInfo')!)
    }
    return userInfo as Account
  }

  getLocalStorageAccountStores(): Store[] {
    const localStorageAccountInfo = this.getUserInfo()
    return localStorageAccountInfo.account_has_accesses.map(access => access.store)
  }

  getLoggedInUserId(): number {
    const userToken = this.getUserToken()
    let payload
    if (userToken) {
      try {
        payload = JSON.parse(Buffer.from(userToken.split('.')[1], 'base64').toString('binary'))
        // console.log('payload: ', payload)
      } catch (error) {
        console.log('invalid token')
      }
    } else {
      return 0
    }

    return payload.id
  }

  getLoggedInUserOrgId(): number {
    const userToken = this.getUserToken()
    let payload
    if (userToken) {
      try {
        payload = JSON.parse(Buffer.from(userToken.split('.')[1], 'base64').toString('binary'))
      } catch (error) {
        console.log('invalid token')
      }
    } else {
      return 0
    }

    return payload.orgId
  }

  async getLoggedInUserInfo(associatedAttributesType?: number): Promise<Account> {
    const userToken = this.getUserToken()

    const payload = JSON.parse(Buffer.from(userToken.split('.')[1], 'base64').toString('binary'))

    const userInfo = await this.accountService.getAccountByIdViaSockets(
      payload.id,
      associatedAttributesType
    )

    return userInfo
  }

  getTokenType(): string {
    const token = this.getUserToken()
    if (token) {
      const payload = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('binary'))
      return payload.type
    }
    return ''
  }

  getUserToken(): string {
    const token = localStorage.getItem('feathers-jwt')
    return token ? token : ''
  }

  getIsAuth(): boolean {
    return this.isAuthenticated
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable()
  }

  getExpirationDate(): string {
    const expiresAt = localStorage.getItem('expiresAt')
    return expiresAt ? expiresAt : ''
  }

  getUserRoles(): number[] {
    const token = this.getUserToken()
    const userRoles = JSON.parse(
      Buffer.from(token.split('.')[1], 'base64').toString('binary')
    ).roleIds

    return userRoles
  }

  canProceed(allowedRoles: number[]): boolean {
    const token = this.getUserToken()
    const currentRoles = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('binary'))
      .roleIds as number[]

    // loop through each role in the current set of roles
    for (const role of allowedRoles) {
      // check if the role is included in the allowed set of roles
      if (currentRoles.includes(role)) {
        // if it is, return true
        return true
      }
    }
    // if none of the roles are allowed, return false
    return false
  }

  logout() {
    // const examsOrderDate = localStorage.getItem('examsOrderDate')
    // const examsOrderId = localStorage.getItem('examsOrderId')
    // const selectAllExamPages = localStorage.getItem('selectAllExamPages')
    // const selectEcgFirstPage = localStorage.getItem('selectEcgFirstPage')
    // // localStorage.clear()
    // examsOrderDate ? localStorage.setItem('examsOrderDate', examsOrderDate) : null
    // examsOrderId ? localStorage.setItem('examsOrderId', examsOrderId) : null
    // selectAllExamPages ? localStorage.setItem('selectAllExamPages', selectAllExamPages) : null
    // selectEcgFirstPage ? localStorage.setItem('selectEcgFirstPage', selectEcgFirstPage) : null

    localStorage.removeItem('userInfo')
    localStorage.removeItem('feathers-jwt')
    localStorage.removeItem('expiresAt')
    localStorage.removeItem('personInfo')

    this.isAuthenticated = false
    this.authStatusListener.next(false)
    if (
      !this.location.path().includes('register') &&
      !this.location.path().includes('recover-password') &&
      !this.location.path().includes('code-login') &&
      !this.location.path().includes('appointment-finished')
    ) {
      this.router.navigateByUrl('/login')
    }

    this.clearLogoutTimer()
  }

  async initData(accountData: Account) {
    if (accountData) {
      this.isAuthenticated = true
      this.authStatusListener.next(true)
      this.loggedInUserStoreId = accountData.id
      // console.log('data.account: ', accountData)
      this.initDataAfterAuthentication(accountData.id)
      this.setLogoutTimer()
    } else {
      this.logout() // User data not received
    }

  }

  private logoutTimer: any

  setLogoutTimer() {
    const expiresAt = Number(this.getExpirationDate()) * 1000

    const currentTime = Math.floor(new Date().getTime())

    const expiresIn = expiresAt - currentTime

    this.zone.runOutsideAngular(() => {
      this.logoutTimer = setTimeout(() => {
        // show native js alert
        alert('A sua sessão expirou. Por favor, faça login novamente.')
        this.logout()
      }, expiresIn)
    })
  }

  clearLogoutTimer() {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer)
      this.logoutTimer = null
    }
  }

  async deleteOldEntries(numberOfDays: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const currentTime = new Date().getTime()
      const fiveDaysInMillis = numberOfDays * 24 * 60 * 60 * 1000 // num of days in milliseconds
      const keysToRemove: string[] = []

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key && key.startsWith('examID:')) {
          const value = localStorage.getItem(key)
          if (value) {
            const entry = JSON.parse(value)
            const versionDate = new Date(entry.version_date).getTime()
            if (currentTime - versionDate > fiveDaysInMillis) {
              keysToRemove.push(key)
            }
          }
        }
      }

      // Remove collected keys after the loop
      keysToRemove.forEach(key => {
        localStorage.removeItem(key)
      })

      resolve()
    })
  }

  async initDataAfterAuthentication(accountId: number, redirectFromLoginPage = false) {
    this.feathersjsService.socket.emit(
      'findExamsToRepeatCount',
      'exams',
      { accountId: accountId },
      (error: any, data: any) => {
        if (!error && data) {
          this.genericRealTimeService.examsToRepeatCountSub.next(data.count)
        }
      }
    )

    if (this.isTechOrDoctor()) {
      this.feathersjsService.socket.emit(
        'getPatientsCheckedInCount',
        'appointments',
        { accountId: accountId },
        (error: any, data: Appointment[]) => {
          if (!error && data) {
            const warningInfos: HeaderInfoWarning[] = []
            data.forEach(appointment => {
              warningInfos.push(AppointmentUtils.infoMsgFromAppt(appointment))
            })

            this.genericRealTimeService.warningInfo.next(warningInfos)
          }
        }
      )
    }

    if (this.isStoreLevel1OrLevel2()) {
      this.feathersjsService.socket.emit(
        'getRequestGroupsToPrint',
        'exam-request',
        { accountId: accountId },
        (error: any, data: any) => {
          console.log('getRequestGroupsToPrint: ', data);
          if (!error && data) {
            const warningInfos: HeaderInfoWarning[] = []
            data.forEach((examReqGroup: ExamRequestGroup) => {
              warningInfos.push(AppointmentUtils.newExamReqInfo(examReqGroup))
            })

            this.genericRealTimeService.warningInfo.next(warningInfos)
          }
        }
      )
    }

    // get available exams for request if external/level1 tech
    if (this.isExternalTechOrTechLevel1()) {
      this.feathersjsService.socket.emit(
        'getAvailableExamsCount',
        'exams',
        (error: any, data: any) => {
          if (!error && data) {
            const countExams = AppointmentUtils.countExams(data)
            this.examsRealTimeService.availableExamsToRequestCount.next(countExams)
          }
        }
      )
    }

    this.feathersjsService.socket.emit(
      'checkAnyUnseenMessages',
      'issues',
      (error: any, data: ApiResponse<Issue>) => {
        if (!error && data) {
          this.ticketsRealTimeService.unreadTickets = data.rows
          if (redirectFromLoginPage && data.count > 0) {
            this.toastService.show(
              ToastType.INFO,
              'Exames',
              'Relembramos que existem exames por repetir.',
              10000
            )
          }
          this.ticketsRealTimeService.unreadTicketsCount.next(data.count)
        }
      }
    )

    this.feathersjsService.socket.emit(
      'findCount',
      'issues',
      { accountId: accountId },
      (error: any, data: any) => {
        if (!error && data) {
          this.genericRealTimeService.newTicketsCount.next(data.count)
        }
      }
    )

    this.feathersjsService.socket.emit(
      'findCount',
      'notifications',
      { accountId: accountId },
      (error: any, data: any) => {
        if (!error && data) {
          this.genericRealTimeService.newNotificationsCount.next(data.count)
        }
      }
    )

    this.notificationService.initNotificationsObservables(accountId)

    if (redirectFromLoginPage) {
      await this.deleteOldEntries(5)
    }
  }

  updateAccountScreenState(screen: string) {
    this.feathersjsService.socket.emit('updateScreenState', 'accounts', {
      accountId: this.loggedInUserStoreId || this.getLoggedInUserId(),
      currentScreen: screen
    })
  }
}