import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Exam } from '../models/exam'
import { Person } from '../models/person'
import { BodyParams, createHttpBody } from '../utils/http-body'
import { ExamFilters } from '../models/exam-filters'
import { ExamState } from '../utils/examStates'
import { ReportState } from '../utils/reportStates'
import { ApiResponse } from '../models/apiResponse'
import { createHttpParams } from '../utils/http-params'
import { ApiOperationTypes } from '../utils/api-operation-types'
import { FeathersjsService } from './feathersjs.service'
import { SocketInterceptorService } from './socket-interceptor.service'
import { ExamsRealTimeService } from './exams-real-time.service'
import { CreateExamData } from '../utils/createExamData'

@Injectable({
  providedIn: 'root'
})
export class ExamsService {
  private url = `${environment.url}/exams/` //server

  constructor(
    private http: HttpClient,
    private feathersService: FeathersjsService,
    private socketInterceptor: SocketInterceptorService,
    private examsRealTimeService: ExamsRealTimeService
  ) { }

  async getExamsByStoreId(
    pageNumber: number,
    pageSize: number,
    examFilters: ExamFilters
  ): Promise<ApiResponse<Exam>> {
    let socketParams: Record<string, any> = {
      page: pageNumber.toString(),
      size: pageSize.toString(),
      operation: ApiOperationTypes.AND,
      associatedAttributes: 3
    }

    for (const [key, value] of Object.entries(examFilters)) {
      if (value) {
        socketParams[key] = value
      }
    }

    try {
      const exams = await this.socketInterceptor.makeFindRequest('exams', socketParams)
      this.examsRealTimeService.currentExamsList = exams.rows
      return exams
    } catch (error) {
      throw error
    }

    // return this.socketInterceptor.makeFindRequest('exams', socketParams)
  }

  async getExamsWithoutFileByStoreId(
    pageNumber: number,
    pageSize: number,
    examFilters: ExamFilters
  ): Promise<ApiResponse<Exam>> {
    let socketParams: Record<string, any> = {
      page: pageNumber.toString(),
      size: pageSize.toString(),
      status_id: ExamState.NOFILE,
      operation: ApiOperationTypes.AND,
      associatedAttributes: 2
    }

    for (const [key, value] of Object.entries(examFilters)) {
      if (value) {
        socketParams[key] = value
      }
    }

    try {
      const exams = await this.socketInterceptor.makeFindRequest('exams', socketParams)
      this.examsRealTimeService.currentExamsList = exams.rows
      return exams
    } catch (error) {
      throw error
    }

    // return this.socketInterceptor.makeFindRequest('exams', socketParams)
  }

  changeExamAssignedToAccountId(examId: number, accountId: number): Promise<Exam> {
    return firstValueFrom(
      this.http.put<Exam>(`${this.url}${examId}`, { tech_id: accountId == 0 ? null : accountId })
    )
  }

  async getExamsAttentionByStoreId(
    pageNumber: number,
    pageSize: number,
    examFilters: ExamFilters
  ): Promise<ApiResponse<Exam>> {
    let socketParams: Record<string, any> = {
      page: pageNumber.toString(),
      size: pageSize.toString(),
      repeat: '1',
      operation: ApiOperationTypes.AND
    }

    for (const [key, value] of Object.entries(examFilters)) {
      if (value) {
        socketParams[key] = value
      }
    }

    try {
      const exams = await this.socketInterceptor.makeFindRequest('exams', socketParams)
      this.examsRealTimeService.currentExamsList = exams.rows
      return exams
    } catch (error) {
      throw error
    }

    // return this.socketInterceptor.makeFindRequest('exams', socketParams)
  }

  getSimilarExamsByStoreIdTypeIdPatientIdentNumLastTwoDays(
    storeId: number,
    examTypeId: number[],
    identNumber: number,
    pageNumber: number,
    pageSize: number
  ): Promise<ApiResponse<Exam>> {
    const today = new Date().toISOString().slice(0, 10)
    const twoDaysAgo = new Date()
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)
    const twoDaysAgoFormatted = twoDaysAgo.toISOString().slice(0, 10)

    const queryParams: Record<string, any> = {
      page: pageNumber.toString(),
      size: pageSize.toString(),
      store_id: storeId,
      createdAt_range: `${twoDaysAgoFormatted},${today}`,
      '$patient.identNumber$': identNumber,
      exam_type_id: examTypeId,
      operation: ApiOperationTypes.AND,
      associatedAttributes: 5
    }

    return firstValueFrom(this.http.get<ApiResponse<Exam>>(`${this.url}`, { params: queryParams }))
  }

  async getReceptionExams(
    pageNumber: number,
    pageSize: number,
    examFilters: ExamFilters
  ): Promise<ApiResponse<Exam>> {
    let socketParams: Record<string, any> = {
      page: pageNumber.toString(),
      size: pageSize.toString(),
      status_id: [ExamState.NEW, ExamState.CONVERTED],
      report_status_id: [ReportState.NEW, ReportState.ONGOING],
      repeat: 0,
      operation: ApiOperationTypes.AND,
      includePriorityOrder: 'true',
      includeExamReopened: 'true',
      associatedAttributes: 1
    }

    for (const [key, value] of Object.entries(examFilters)) {
      if (value) {
        socketParams[key] = value
      }
    }

    try {
      const exams = await this.socketInterceptor.makeFindRequest('exams', socketParams)
      this.examsRealTimeService.currentExamsList = exams.rows
      return exams
    } catch (error) {
      throw error
    }

    // return this.socketInterceptor.makeFindRequest('exams', socketParams)
  }

  transferExam(examId: number, transferTo: number): Promise<Exam> {
    return firstValueFrom(
      this.http.put<Exam>(`${this.url}${examId}`, { transfered_id: transferTo })
    )
  }

  async getExamsWithNewAndOnGoingReports(
    pageNumber: number,
    pageSize: number,
    examFilters: ExamFilters
  ): Promise<ApiResponse<Exam>> {
    let socketParams: Record<string, any> = {
      page: pageNumber.toString(),
      size: pageSize.toString(),
      repeat: 0, // Convert to string
      status_id: ExamState.REPORTED,
      report_status_id: [ReportState.NEW, ReportState.ONGOING, ReportState.WAITINGAPPROVAL],
      operation: ApiOperationTypes.AND,
      includePriorityOrder: 'true', // Convert to string
      includeReopenedOrder: 1,
      associatedAttributes: 1
    }

    for (const [key, value] of Object.entries(examFilters)) {
      if (value) {
        socketParams[key] = value
      }
    }

    try {
      const exams = await this.socketInterceptor.makeFindRequest('exams', socketParams)
      this.examsRealTimeService.currentExamsList = exams.rows
      return exams
    } catch (error) {
      throw error
    }

    // return this.socketInterceptor.makeFindRequest('exams', socketParams)
  }

  searchExamsWithRegexPost(searchValue: string): Promise<any | null> {
    let params = new HttpParams()

    let storeParams: any = {}
    let examParams: any = {}

    // check if searchValue is only spaces
    if (!searchValue.trim()) {
      // return null promise
      return Promise.resolve(null)
    }

    if (Number(searchValue)) {
      searchValue.length <= 5
        ? (examParams['id'] = searchValue)
        : (examParams['$patient.healthNumber$'] = searchValue)
    } else {
      const inputParts = searchValue
        .split(' ')
        .map(name => name.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
        .join('%') // Use '%' as wildcard for Op.like

      storeParams['name'] = inputParts
      examParams['$patient.name$'] = inputParts
    }

    params = params.append('storeParams', JSON.stringify(storeParams))
    params = params.append('examParams', JSON.stringify(examParams))

    const httpBody = createHttpBody({ storeParams: storeParams, examParams: examParams })

    return firstValueFrom(this.http.post<any>(`${environment.url}/exam-search`, httpBody))
  }

  async getExamsReadyToSign(
    pageNumber: number,
    pageSize: number,
    examFilters: ExamFilters
  ): Promise<ApiResponse<Exam>> {
    let socketParams: Record<string, any> = {
      page: pageNumber.toString(),
      size: pageSize.toString(),
      report_status_id: ReportState.WAITINGAPPROVAL,
      repeat: 0,
      operation: ApiOperationTypes.AND,
      includePriorityOrder: 'true',
      includeReopenedOrder: 1,
      associatedAttributes: 1
    }

    for (const [key, value] of Object.entries(examFilters)) {
      if (value) {
        socketParams[key] = value
      }
    }

    try {
      const exams = await this.socketInterceptor.makeFindRequest('exams', socketParams)
      this.examsRealTimeService.currentExamsList = exams.rows
      return exams
    } catch (error) {
      throw error
    }

    // return this.socketInterceptor.makeFindRequest('exams', socketParams)
  }

  async getExamsToRepeat(
    pageNumber: number,
    pageSize: number,
    examFilters: ExamFilters
  ): Promise<ApiResponse<Exam>> {
    let socketParams: Record<string, any> = {
      page: pageNumber.toString(),
      size: pageSize.toString(),
      repeat: '1',
      operation: ApiOperationTypes.AND,
      associatedAttributes: 1
    }

    for (const [key, value] of Object.entries(examFilters)) {
      if (value) {
        socketParams[key] = value
      }
    }

    try {
      const exams = await this.socketInterceptor.makeFindRequest('exams', socketParams)
      this.examsRealTimeService.currentExamsList = exams.rows
      return exams
    } catch (error) {
      throw error
    }

    // return this.socketInterceptor.makeFindRequest('exams', socketParams)
  }

  async getLastNExamsFromStore(storeId: number, numExams: number): Promise<ApiResponse<Exam>> {
    let socketParams: Record<string, any> = {
      page: '0',
      size: 100,
      store_id: storeId,
      operation: ApiOperationTypes.AND
    }

    try {
      const exams = await this.socketInterceptor.makeFindRequest('exams', socketParams)
      return exams
    } catch (error) {
      throw error
    }

    // return this.socketInterceptor.makeFindRequest('exams', socketParams)
  }

  async getFinalizedExams(
    pageNumber: number,
    pageSize: number,
    examFilters: ExamFilters
  ): Promise<ApiResponse<Exam>> {
    let socketParams: Record<string, any> = {
      page: pageNumber.toString(),
      size: pageSize.toString(),
      report_status_id: ReportState.SIGNED,
      operation: ApiOperationTypes.AND,
      associatedAttributes: 6
    }

    for (const [key, value] of Object.entries(examFilters)) {
      if (value) {
        socketParams[key] = value
      }
    }

    try {
      const exams = await this.socketInterceptor.makeFindRequest('exams', socketParams)
      this.examsRealTimeService.currentExamsList = exams.rows
      return exams
    } catch (error) {
      throw error
    }

    // return this.socketInterceptor.makeFindRequest('exams', socketParams)
  }

  updateExam(
    examId: number,
    repeat?: number,
    repeatReason?: string,
    statusId?: number,
    examTypeId?: number,
    patientId?: number,
    accountId?: number,
    techId?: number | null
  ): Promise<Exam> {
    const params: BodyParams = createHttpBody(
      {
        repeat: repeat,
        repeat_reason: repeatReason,
        status_id: statusId,
        exam_type_id: examTypeId,
        patient_id: patientId,
        account_id: accountId,
        tech_id: techId
      },
      true
    )
    return firstValueFrom(this.http.put<Exam>(`${this.url}${examId}`, params))
  }

  updateExamReportState(examId: number, reportStateId: number): Promise<Exam> {
    return firstValueFrom(
      this.http.put<Exam>(`${this.url}${examId}`, { report_status_id: reportStateId })
    )
  }

  reopenExam(examId: number, reopenReason: string): Promise<Exam> {
    return firstValueFrom(
      this.http.put<Exam>(`${this.url}${examId}`, {
        reopened_reason: reopenReason,
        reopened: 1,
        report_status_id: ReportState.ONGOING
      })
    )
  }

  updateExamDate(examId: number, exam_date: string): Promise<Exam> {
    return firstValueFrom(this.http.put<Exam>(`${this.url}${examId}`, { exam_date }))
  }

  async getExamInfoByIdViaSockets(examId: number, associatedAttributes?: number): Promise<Exam> {
    return this.socketInterceptor.makeGetRequest('exams', examId, {
      associatedAttributes
    })
  }

  getExamById(examId: number, associatedAttributesType?: number): Promise<Exam> {
    const httpParams = createHttpParams({
      associatedAttributes: associatedAttributesType
    })

    return firstValueFrom(this.http.get<Exam>(`${this.url}${examId}`, { params: httpParams }))
  }

  extendExamLock(examId: number): Promise<Exam> {
    return this.socketInterceptor.makeCustomRequest(
      'exams',
      'extendExamLock',
      { examId },
      undefined
    )
  }

  checkExamLocked(examId: number): Promise<Exam> {
    const httpParams = createHttpParams({
      checkLocked: true
    })
    return firstValueFrom(this.http.get<Exam>(`${this.url}${examId}`, { params: httpParams }))
  }

  unlockExam(examId: number) {
    if (examId) {
      this.feathersService.socket.emit('unlockExam', 'exams', { examId: examId })
    }
  }

  emitRepeatState(
    examId: number,
    repeatState: number,
    repeatReason: string,
    store_id: number,
    transfered_id?: number,
    account_id?: number,
    current_tech_id?: number
  ) {
    this.feathersService.socket.emit('emitExamRepetitionState', 'exams', {
      id: examId,
      repeat: repeatState,
      repeatReason: repeatReason,
      store_id: store_id,
      transfered_id: transfered_id,
      account_id: account_id,
      current_tech_id: current_tech_id
    })
  }

  async lockExam(examId: number, locked_id: number): Promise<Exam | undefined> {
    return firstValueFrom(
      this.http.put<Exam>(`${this.url}${examId}`, { locked_id: locked_id, locked_at: new Date() })
    )
  }

  getExamByIdNoLock(examId: number, associatedAttributes?: number): Promise<Exam> {
    const httpHeaders = new HttpHeaders({
      nolock: 'true'
    })
    const httpParams = createHttpParams({
      associatedAttributes: associatedAttributes
    })
    return firstValueFrom(
      this.http.get<Exam>(`${this.url}${examId}`, { headers: httpHeaders, params: httpParams })
    )
  }

  updateExamState(examId: number, status_id: number): Promise<Exam> {
    return firstValueFrom(this.http.put<Exam>(`${this.url}${examId}`, { status_id }))
  }

  updateExamPriority(examId: number, priority: number): Promise<Exam> {
    return firstValueFrom(this.http.put<Exam>(`${this.url}${examId}`, { priority }))
  }

  addExternalExam(
    external_exam_type_id: number,
    account_id: number,
    patient_id: number,
    exam_date: string
  ): Promise<Exam> {
    return firstValueFrom(
      this.http.post<Exam>(this.url, {
        exam_type_id: 9, // external exam type id
        account_id,
        store_id: 167,
        status_id: 4,
        patient_id,
        repeat: 0,
        priority: 0,
        exam_date,
        external: 1,
        external_exam_type_id
      })
    )
  }

  addNewExamWithoutFile(
    exam_type_id: number,
    account_id: number,
    patient_id: number,
    store_id: number
  ): Promise<Exam> {
    return firstValueFrom(
      this.http.post<Exam>(this.url, {
        exam_type_id,
        account_id,
        store_id,
        status_id: 1,
        report_status_id: 1,
        patient_id,
        repeat: 0,
        priority: 0
      })
    )
  }

  createExamViaSockets(examData: CreateExamData): Promise<Exam[]> {
    return this.socketInterceptor.makeCustomRequest('exams', 'submitExam', examData, undefined)
  }

  deleteExam(examId: number): Promise<Exam> {
    return firstValueFrom(this.http.delete<Exam>(`${this.url}${examId}`))
  }

  updateDeleteReason(examId: number, deleteReason: string): Promise<Exam> {
    return firstValueFrom(
      this.http.put<Exam>(`${this.url}${examId}`, { delete_reason: deleteReason })
    )
  }

  sendEmailReport(examId: number, email: String): Promise<any> {
    const httpHeaders = new HttpHeaders({
      'X-Service-Method': 'sendEmailReport',
      'Content-Type': 'application/json'
    })
    return firstValueFrom(
      this.http.post<Exam>(`${this.url}`, { email, examId }, { headers: httpHeaders })
    )
  }

  revertTransferedState(examId: number): Promise<any> {
    const httpHeaders = new HttpHeaders({
      'X-Service-Method': 'revertTransferedState',
      'Content-Type': 'application/json'
    })
    return firstValueFrom(
      this.http.post<Exam>(`${this.url}`, { examId }, { headers: httpHeaders })
    )
  }

  translateConclusion(conclusion: string, language: string): Promise<any> {
    const httpHeaders = new HttpHeaders({
      'X-Service-Method': 'translateConclusion',
      'Content-Type': 'application/json'
    })
    return firstValueFrom(
      this.http.post<any>(`${this.url}`, { conclusion, language }, { headers: httpHeaders })
    )
  }
}
